import React, { useState, useEffect } from 'react';
import { getLocale } from '@didi/di18n-react';
import { Select } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { getCookie, handleLangCookie } from '../../utils/Tool';
import Introduction from '../Introduction';
import './index.scss';

const { Option } = Select;
const NavigationTopGuide = props => {
  const { title, langKeyList, source, langDes } = props;
  const {
    lanMap,
    lanDes,
    lanPair,
    surveyBusType
  } = useSelector(state => state.questionContent);
  const [currentLangDes, setCurrentLangDes] = useState('简体中文');

  const handleLanChange = value => {
    handleLangCookie(lanMap, value);
  };

  const languageSelectComponent = () => (
    <>
      <span className="lang-before-icon"><GlobalOutlined /></span>
      <Select
        style={{ width: '100%' }}
        defaultValue={currentLangDes}
        key={currentLangDes}
        onChange={handleLanChange}
        getPopupContainer={triggerNode => triggerNode.parentNode}
      >
        {
          langKeyList.map(item => (
            <Option value={item} key={`lan-list${lanMap[item]}`}>{lanDes[item]}</Option>
          ))
        }
      </Select>
    </>
  );

  const renderPC = () => (
    <div className="question-main-banner-pc">
      <div className="questionnaire-banner-main">
        <div className="question-main-banner-title">
          <div className="multi-questionaire-lan-select">
            <div>
              {languageSelectComponent()}
            </div>
          </div>
          <div className="title">
            {title}
          </div>
          {/* {renderRemark()} */}
        </div>
        <div className="question-main-banner-pic">
          <div className="img" />
        </div>
      </div>
    </div>
  );

  // 根据不同的主题给类名
  const getClassNames = () => {
    let prefixCls = '';
    let mainPrefixCls = '';
    switch (surveyBusType) {
      case 'PROFESSIONAL_SERVICES':
        prefixCls = 'question-main-banner-mobile';
        mainPrefixCls = 'flex questionnaire-main';
        break;
      case 'NON_PROFESSIONAL_SERVICES':
        prefixCls = 'question-main-banner-mobile question-main-banner-mobile-blue';
        mainPrefixCls = 'flex questionnaire-main questionnaire-main-blue';
        break;
      default:
        prefixCls = 'question-main-banner-mobile';
        mainPrefixCls = 'flex questionnaire-main';
        break;
    }

    return {
      prefixCls,
      mainPrefixCls,
    };
  };


  const rednerMobile = () => (
    <Introduction
      title={title}
      questionDes=""
      langKeyList={langKeyList}
      getClassNames={getClassNames()}
    />
  );

  useEffect(() => {
    const lang = getLocale('lang');
    // 导航页只有两种语言
    if (['zh-CN', 'en-US'].includes(lang)) {
      setCurrentLangDes(lanPair[lang]);
    }
  }, [langDes]);

  return (
    <>
      {
        source === 'pc' ? renderPC() : rednerMobile()
      }
    </>
  );
};

export default NavigationTopGuide;
