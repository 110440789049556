import React, { useState, useEffect } from 'react';
import { Form, Checkbox, Input, Tooltip } from 'antd';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { di18n } from '@ultra/common';
import Must from '../../images/must.svg';
import { ExclamationCircleFilled } from '@ant-design/icons';
import './index.scss';
const { intl } = di18n;
import {
  setGeneralData,
  setValidateResult,
  updateAttributeData,
  setProcessRate,
  setGeneralMultiData,
  resetInitRequired,
  updateCurrentSubject,
  setPrevOptionIds,
  setCurOptionIds
} from '../../store/questionnaireNew/questionContentSlice'; // 引入actions

const { TextArea } = Input;
const multiWidget = props => {
  const { widgetKey } = props;
  const {
    draftData,
    validateRules,
    validateResult,
    effectData,
    attributeData,
    respondentsGroup,
    validateFlag,
    currentRespondentCode,
    surveyBusType
  } = useSelector(state => state.questionContent);

  const dispatch = useDispatch();
  const {
    label,
    labelStyle,
    other,
    tips,
    hidden,
    number,
    related,
    required,
    direction,
    effectOption,
    options,
    exclusive,
    hasSerialNumber
  } = attributeData[widgetKey];

  const rules = validateRules[widgetKey];

  const otherRules = rules.otherRules;
  const effect = effectData[widgetKey];
  const requiredIcon = (related || number === 1) ? true : false;

  const [multiValue, setMultiValue] = useState();
  const [inputValue, setInputValue] = useState();
  const [updateData, setUpdateData] = useState();
  const [initFlag, setInitFlag] = useState(false);
  const [disabledFlag, setDisabledFlag] = useState(false);

  useEffect(() => {
    // 初始化单选的数据结构以及将草稿值赋予value
    if (respondentsGroup && draftData) {
      const inputValueInit = {};
      other.map(item => {
        inputValueInit[item] = '';
      })
      const widgetDraftData = draftData[widgetKey];
      if (widgetDraftData) {
        const draftItem = widgetDraftData[currentRespondentCode];
        if (draftItem.length > 0) {
          const multi = draftItem.map(item => {
            inputValueInit[item.option] = item.value;
            if (effectOption.length > 0) {
              effectFun(item.option);
            }
            return item.option
          })
          setMultiValue(multi);
          if (rules.controlRegular && rules.controlRegular.max) {
            if (multi.length < Number(rules.controlRegular.max)) {
              setDisabledFlag(false);
            } else {
              setDisabledFlag(true);
            }
          }
          // 初始化赋值&校验
          setValueAndValidate(draftItem);
        }
      }
      setInputValue(inputValueInit);
      setInitFlag(true);
    } else {
      setInitFlag(true);
    }
  }, [draftData[widgetKey], respondentsGroup]);

  useEffect(() => {
    if (updateData) {
      dispatch(resetInitRequired({}));
      setValueAndValidate(updateData);
    }
  }, [updateData]);
  useEffect(() => {
    if (attributeData && initFlag) {
      dispatch(setProcessRate({ validateResult, attributeData }));
    }
  }, [updateData, validateResult[widgetKey], initFlag]);

  // hidden为true时，需要删除当前组件的值
  useEffect(() => {
    if (initFlag && hidden) {
      if (multiValue) {
        setMultiValue(null);
      }
      if (inputValue) {
        setInputValue(null);
      }
    }
  }, [hidden, initFlag, multiValue, inputValue])

  const setValueAndValidate = (paramData) => {
    dispatch(setGeneralData({ id: widgetKey, data: paramData }));
    dispatch(updateCurrentSubject({ data: widgetKey }));
    dispatch(setGeneralMultiData({ id: widgetKey, respondentCode: currentRespondentCode, data: paramData }));
    // 数据校验
    const widgetValidate = validateFun(rules, paramData);
    let otherValidate;
    paramData.map(item => {
      if (other.includes(item.option)) {
        otherValidate = { ...otherValidate, [item.option]: validateFun(otherRules[item.option], item.value, true) };
      }
    });
    const validateData = Object.assign({}, widgetValidate, { otherResult: otherValidate });
    dispatch(setValidateResult({ id: widgetKey, data: validateData }));

  }
  const handleOnChange = v => {
    // 记录上次选择的id
    if (multiValue) {
      dispatch(setPrevOptionIds({ data: multiValue }));
    }

    const newAddData = v[v.length - 1];
    if (exclusive) {
      if (newAddData === exclusive) {
        v = [exclusive];
      } else {
        if (v.includes(exclusive)) {
          const index = v.indexOf(exclusive);
          v.splice(index, 1);
        }
      }
    }

    const data = v.map(item => {
      return {
        option: item,
        value: (inputValue && inputValue[item]) ? inputValue[item] : ''
      };
    });
    // 记录本次选择的id
    dispatch(setCurOptionIds({ data: v }));

    setMultiValue(v);
    setUpdateData(data);
    if (rules.controlRegular && rules.controlRegular.max) {
      if (v.length < Number(rules.controlRegular.max)) {
        setDisabledFlag(false);
      } else {
        setDisabledFlag(true);
      }
    }
  }

  const handleItemChange = e => {
    // 每次选中这个选项都清空其对应的输入框
    const updateData = { ...inputValue, [e.target.value]: '' };
    setInputValue(updateData);
    const checked = {
      option: e.target.value,
      status: e.target.checked
    };
    if (effectOption.length > 0) {
      effectFun(checked);
    }
  }

  const handleChangeInput = (e, optionKey) => {
    const updateData = { ...inputValue, [optionKey]: e.target.value };
    setInputValue(updateData);
  }
  const handleBlur = () => {
    const data = multiValue.map(item => {
      return {
        option: item,
        value: inputValue?.[item] ? inputValue[item]?.replace(/(\s*$)/g, '') : ''
      }
    });
    setUpdateData(data);
  };

  // 显隐逻辑处理
  const effectFun = option => {
    // 刚进来的时候没有status
    if (option.status === undefined) {
      if (effectOption.includes(option)) {
        effect[option].map(item => {
          const { hidden, effectId } = item;
          const data = {
            hidden: hidden,
            currentRespondentCode: currentRespondentCode
          };
          // 剔除其他选项影响的题目id
          dispatch(updateAttributeData({ id: effectId, data }));
        })
      }
    }

    // 只对本次修改的选项做属性修改
    if (option.status) {
      if (effectOption.includes(option.option)) {
        effect[option.option].map(item => {
          const { hidden, effectId } = item;
          const data = {
            hidden: hidden,
            currentRespondentCode: currentRespondentCode
          }
          dispatch(updateAttributeData({ id: effectId, data: data }));
        })
      }
    }

    if (!option.status) {
      let lastEffectId = [];
      // 记录本次选项操作之前的选项集所影响的题目id，这些题目不修改其属性
      multiValue?.map(itemOpt => {
        if (effectOption.includes(itemOpt) && itemOpt !== option.option) {
          effect[itemOpt].map((item, i) => {
            lastEffectId.push(item.effectId);
          })
        }
      });
      if (effectOption.includes(option.option)) {
        effect[option.option].map(item => {
          const { hidden, effectId } = item;
          const data = {
            hidden: !hidden,
            currentRespondentCode: currentRespondentCode
          }
          // 剔除其他选项影响的题目id
          if (!lastEffectId.includes(effectId)) {
            dispatch(updateAttributeData({ id: effectId, data: data }));
          }
        })
      }
    }

  }

  // 校验方法
  const validateFun = (rule, content, other = false) => {
    const { max, min, controlRegular, required } = rule;

    const msgArr = [
      intl.t('亲，本题要求最少选择') + ' ' + controlRegular?.min + ' ' + intl.t('个选项~'),
      intl.t('亲，本题要求最少选择') + ' ' + controlRegular?.min + ' ' + intl.t('个选项，最多选择') + ' ' + controlRegular?.max + ' ' + intl.t('个选项~'),
      intl.t('亲，此项必填的哈~')
    ];

    if (controlRegular) {
      if (controlRegular.min) {
        if (content.length < Number(controlRegular?.min)) {
          if (controlRegular.max) {
            return {
              error: false,
              msg: msgArr[1]
            }
          } else {
            return {
              error: false,
              msg: msgArr[0]
            }
          }
        }
      }
    }

    if (max && max !== 0) {
      if (content.length > max) {
        return {
          error: false,
          msg: msgArr[2]
        }
      }
    }

    if (min && min !== 0) {
      if (content.length < min) {
        return {
          error: false,
          msg: msgArr[2]
        }
      }
    }

    if (required) {
      if (!content || content.length === 0) {
        return {
          error: false,
          msg: msgArr[2]
        }
      }
    }
    return {
      error: true,
      msg: ''
    }
  }

  const renderRadio = () => {
    if (direction === 'horizontal') {
      return (
        <>
          <Checkbox.Group
            className={`checkbox-option-${direction}`}
            onChange={handleOnChange}
            value={multiValue}
          >
            {
              options.map(opt => {
                let disabled;
                if (!multiValue) {
                  disabled = false;
                } else {
                  if (disabledFlag && !multiValue.includes(opt.key)) {
                    disabled = true;
                  } else {
                    disabled = false;
                  }
                }
                return (
                  <div key={widgetKey + opt.key + 'div'}>
                    <Checkbox value={opt.key} key={widgetKey + opt.key} onChange={handleItemChange} disabled={disabled}>
                      {decodeURIComponent(opt.value)}
                    </Checkbox>

                    {
                      ((other.length > 0) &&
                        multiValue &&
                        multiValue.includes(opt.key) &&
                        other.includes(opt.key)) ?
                        renderInput(opt.key) : ''
                    }
                  </div>
                )
              })
            }
          </Checkbox.Group>
        </>
      )
    }

    if (direction === 'two') {
      return (
        <>
          <Checkbox.Group
            className={`checkbox-option-${direction}`}
            onChange={handleOnChange}
            value={multiValue}
          >
            {
              options.map((opt, k) => {
                const lefKey = opt;
                const rightKey = options[k + 1];
                let leftDisabled, rightDisabled
                if (!multiValue) {
                  leftDisabled = false;
                  rightDisabled = false;
                } else {
                  if (disabledFlag && !multiValue.includes(lefKey.key)) {
                    leftDisabled = true;
                  } else {
                    leftDisabled = false;
                  }
                  if (disabledFlag && !multiValue.includes(rightKey?.key)) {
                    rightDisabled = true;
                  } else {
                    rightDisabled = false;
                  }
                }
                if ((k + 1) % 2 !== 0) {
                  return (
                    <div key={widgetKey + lefKey.key + 'row'} style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ width: '49%' }} key={widgetKey + lefKey.key + 'left div'}>
                        <Checkbox
                          value={lefKey.key}
                          key={widgetKey + lefKey.key + 'left'}
                          onChange={handleItemChange}
                          disabled={leftDisabled}

                        >
                          <Tooltip placement="topLeft" title={lefKey.des}>
                            <div>{decodeURIComponent(lefKey.value)}</div>
                          </Tooltip>
                        </Checkbox>
                        {
                          ((other.length > 0) &&
                            multiValue &&
                            multiValue.includes(lefKey.key) &&
                            other.includes(lefKey.key)) ?
                            renderInput(lefKey.key) : ''
                        }
                      </div>
                      {
                        rightKey ?
                          <div style={{ width: '49%' }} key={widgetKey + rightKey.key + 'right div'}>
                            <Checkbox
                              value={rightKey.key}
                              key={widgetKey + rightKey.key + 'right'}
                              onChange={handleItemChange}
                              disabled={rightDisabled}
                            >
                              <Tooltip placement="topLeft" title={rightKey.des}>
                                <div>{decodeURIComponent(rightKey.value)}</div>
                              </Tooltip>
                            </Checkbox>
                            {
                              ((other.length > 0) &&
                                multiValue &&
                                multiValue.includes(rightKey.key) &&
                                other.includes(rightKey.key)) ?
                                renderInput(rightKey.key) : ''
                            }
                          </div>
                          : ''
                      }
                    </div>
                  )
                }

              })
            }
          </Checkbox.Group>
        </>
      );
    }
    // 默认垂直展示选项
    return (
      <>
        <Checkbox.Group
          className={`checkbox-option-${direction}`}
          onChange={handleOnChange}
          value={multiValue}
        >
          {
            options?.map(opt => {
              let disabled
              if (!multiValue) {
                disabled = false;
              } else {
                if (disabledFlag && !multiValue.includes(opt.key)) {
                  disabled = true;
                } else {
                  disabled = false;
                }
              }
              return (
                <div key={widgetKey + opt.key + 'div'}>
                  <Checkbox value={opt.key} key={widgetKey + opt.key} onChange={handleItemChange} disabled={disabled}>
                    <Tooltip placement="topLeft" title={opt.des}>
                      <div>{decodeURIComponent(opt.value)}</div>
                    </Tooltip>
                  </Checkbox>
                  {
                    ((other.length > 0) &&
                      multiValue &&
                      multiValue.includes(opt.key) &&
                      other.includes(opt.key)) ?
                      renderInput(opt.key) : ''
                  }
                </div>
              )
            })
          }
        </Checkbox.Group>
      </>
    );

  }

  const formatterCount = (e = '', optionKey = null) => {
    const { min } = otherRules[optionKey];
    const max = otherRules[optionKey].max ? otherRules[optionKey].max : 2000;
    const count = e?.replace(/(\s*$)/g, '')?.length || 0;
    if (min && Number(min)) {
      return {
        formatter() {
          return `${intl.t('为了反馈更有效，')}${intl.t('请至少输入{num}字', { num: min })}, ${count}/${max}`;
        }
      }
    }
    return {
      formatter() {
        return `${count}/${max}`;
      }
    }
  };

  const renderInput = optionKey => {
     const validateStatus =  validateFlag ? (validateResult[widgetKey]?.otherResult ?
              (validateResult[widgetKey]?.otherResult[optionKey]?.error ? '' : 'error') : '') : ''
    return (
      <div className={classnames('checkbox-textarea', {
        'textarea-content-blue': surveyBusType === 'NON_PROFESSIONAL_SERVICES',
        'textarea-content-orange': surveyBusType !== 'NON_PROFESSIONAL_SERVICES'
      })}>
        <Form.Item
          validateStatus={validateStatus}
        >
          <TextArea
            key={widgetKey + 'textarea'}
            value={inputValue[optionKey]}
            placeholder={placeholder(optionKey)}
            rows={4}
            onChange={(e) => handleChangeInput(e, optionKey)}
            onBlur={handleBlur}
            showCount={formatterCount(inputValue ? inputValue[optionKey] : '', optionKey)}
            maxLength={2000}
          />
          {validateStatus === 'error' && (
            <div className="error-tip">
              <ExclamationCircleFilled />
              <span style={{ paddingLeft: '5px' }}>{intl.t('亲，此项必填的哈~')}</span>
            </div>
          )}
        </Form.Item>
      </div>
    )
  }

  const placeholder = optionKey => {
    const requiredContent = otherRules[optionKey].required ? intl.t('必填') : '';
    let desContent = '';
    options.map((item, i) => {
      if (item.key === optionKey && item.des !== '') {
        desContent = '(' + item.des + ')';
      }
    });
    return requiredContent + desContent;
  }

  const renderErrorMsg = () => {
    const { required } = rules;
    if (validateFlag) {
      if (validateResult[widgetKey]) {
        if (!validateResult[widgetKey].error && required) {
          return (
            <>
              <div className="error-tip">
                <ExclamationCircleFilled />
                <span style={{ paddingLeft: '5px' }}>
                  {validateResult[widgetKey].msg ? validateResult[widgetKey].msg : intl.t('亲，此项必填的哈~')}
                  {/* {intl.t(validateResult[widgetKey].msg)} */}
                </span>
              </div>
            </>
          );
        }
      }
    }
    return '';
  };
  return (
    <>
      <div
        className={`checkbox-div-hidden-${hidden}`}
        style={{ position: 'relative', paddingTop: '14px' }}
        key={widgetKey}
        id={`id_${widgetKey}`}
      >
        <div
          className={`div-label ${related}`}
        >
          <div className={`textarea-div-label-${required}-${requiredIcon}`}>
            <img src={Must} />
          </div>
          {
            number && hasSerialNumber ? <div style={{ wordBreak: 'keep-all', display: 'inline-block' }}>{number}. </div> : ''
          }
          {
            labelStyle ? <div className="label-line" dangerouslySetInnerHTML={{ __html: decodeURIComponent(labelStyle) }} />
              : <div>{decodeURIComponent(label)}</div>
          }
        </div>
        <div className={classnames(`checkbox-content checkbox-content-related-${related}`, {
          'checkbox-content-blue': surveyBusType === 'NON_PROFESSIONAL_SERVICES'
        })}>
          {tips ? (
            <div className="pc-question-tip">{decodeURIComponent(tips)}</div>
          ) : null}
          {renderRadio()}
        </div>
        {renderErrorMsg()}
      </div>
    </>
  );
};

export default multiWidget;