import { getLocale, setLocale } from '@didi/di18n-react';
import { di18n } from '@ultra/common';
import request from './request'
const { intl } = di18n;
/**
 * param 将要转为URL参数字符串的对象
 * key URL参数字符串的前缀
 * encode true/false 是否进行URL编码,默认为true
 *
 * return URL参数字符串
 */
export function urlEncode(param, key, encode) {
  if (param == null) return '';
  var paramStr = '';
  var t = typeof (param);
  if (t == 'string' || t == 'number' || t == 'boolean') {
    paramStr += '&' + key + '=' + ((encode == null || encode) ? encodeURIComponent(param) : param);
  } else {
    for (var i in param) {
      var k = key == null ? i : key + (param instanceof Array ? '[' + i + ']' : '.' + i);
      paramStr += urlEncode(param[i], k, encode);
    }
  }
  return paramStr;
}

// export function parseQueryString (url) {
//   var reg_url = /^[^\?]+\?([\w\W]+)$/,
//     reg_para = /([^&=]+)=([\w\W]*?)(&|$)/g, //g is very important
//     arr_url = reg_url.exec(url),
//     ret = {};
//   if (arr_url && arr_url[1]) {
//     var str_para = arr_url[1], result;
//     while ((result = reg_para.exec(str_para)) != null) {
//       ret[result[1]] = result[2];
//     }
//   }
//   return ret;
// }

/**
 * param 要判断的对象
 *
 * return 返回是否为空对象
 */
export function isEmptyObject(O) {
  for (var x in O) {
    return false;
  }
  return true;
}

export const debounce = (fn, delay) => {
  var timer = null;
  return function () {
    var self = this, args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(self, args);
    }, delay);
  };
};

export const throttle = (fn, threhhold) => {
  threhhold = threhhold || 300;
  var last, deferTimer;

  return function () {
    var self = this;
    var args = arguments;
    var now = new Date().getTime();

    if (last && now < last + threhhold) {
      clearTimeout(deferTimer);
      deferTimer = setTimeout(function () {
        last = now;
        fn.apply(self, args);
      }, threhhold);
    } else {
      last = now;
      fn.apply(self, args);
    }
  };
};

export const getUrlParams = (url, params) => {
  let array = url.split('?')[1] && url.split('?')[1].split('&');
  let result = '';
  array && array.forEach(item => {
    item = item.split('=');
    if (item[0] == params) {
      result = item[1];
    }
  });
  return result;
};

export const getMobile = () => {
  if (typeof window !== 'undefined') {
    const reviewId = getUrlParams(window.location.href, 'reviewId');
    if (reviewId) {
      return /Android|webOS|iPhone|iPod|BlackBerry/i.test(window.navigator.userAgent);
    }
    else {
      return false;
    }
  }
  else {
    return false;
  }
};

// 获取设备类型
export function getDeviceType() {
  const userAgent = window.navigator.userAgent;
  if (/Mobi|Android/i.test(userAgent)) {
    return 'H5'; // 移动设备
  } else {
    return 'PC'; // PC 端
  }
}

const getRoleCookieValue = (name) => {
  const result = document.cookie.match(`(^|[^;]+)\\s*${name}\\s*=\\s*([^;]+)`);
  return result ? result.pop() : '';
};

const changeRoleValue = (val) => {
  document.cookie = `ct_role=${val};path=/`;
};

// 获取角色
export const getRoleValue = async () => {
  let cookieRole = getRoleCookieValue('ct_role');
  if (cookieRole) {
    return cookieRole
  } else {
    let res = await request({
      method: 'get',
      url: `/ctActivity/api/common/getRoles`,
      params: {}
    });
    if (res.data && res.data[0]) {
      changeRoleValue(res.data[0].roleCode)
      return res.data[0].roleCode
    } else {
      return ''
    }
  }
};

export function getLang() {
  return Cookies.default.get('lang');
};

export const setCookie = (name, value) => {
  const Days = 3000;
  const exp = new Date();
  exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
  document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString() + "; path=/";
};

export const getCookie = (name) => {
  var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if (arr = document.cookie.match(reg))
    return unescape(arr[2]);
  else
    return null;
};

export const getLangCode = (lang) => {
  const lanPair = {
    'zh-CN': 0,
    'en-US': 1,
    'ja-JP': 2,
    'es-ES': 3,
    'pt-BR': 4,
    'ru-RU': 5,
  };
  return lanPair[lang];
};

/**
 * 获取路由中等号后面的值
 */
export const getCaption = (str) => {
  let result = {};
  const paramStr = str.substr(str.indexOf('?') + 1);
  const arr = paramStr.split('&');
  for (let i = 0; i < arr.length; i++) {
    const entry = arr[i].split('=');
    result[entry[0]] = entry[1];
  }
  return result;
};

/**
 * 单独导航页统一改lang cookie
 */
export const handleLangCookie = (lanMap, value) => {
  const lang = lanMap[value];
  setLocale(lang, {
    cookieLocaleKey: 'lang',
  });
  setCookie('ct-lang', lang);
  window.location.reload();
};

export const setEncode = async (data) => {
  // 获取匿名key,提交问卷时，需要传入busType=1获取key,获取进度时，传入2
  // 原接口：/api/anymous/encode
  return getKey(data.encodeText, data.busType);
}

// 按题目维度保存草稿
export const sendAnswerBySubject = (data) => request('/ctAnswer/api/answer/saveDraft', {
  method: 'POST',
  data
});

// 拒评列表
export const getRefuseList = params => request('/ctActivity/api/answer/activity/participant/getRejects', {
  method: 'GET',
  params,
});

// 获取问卷进度
export const getProcessAll = (activityId, respondentTypeCode) => request('/ctAnswer/api/answer/gertQuestionnaireProgressRate', {
  method: 'POST',
  data: {
    activityId,
    respondentTypeCode
  },
});

// 2.0获取问卷信息
// 原接口：/api/questionnaire/getAnswerQuestionnaire
export const getPage = (language, questionnaireVersionId, activityId, respondentTypeCode) => request('/ctQuestionnaire/api/answer/questionnaire/getAnswerQuestionnaire', {
  params: { language, questionnaireVersionId, respondentTypeCode, activityId },
});

// 通过问卷id获取渲染id
// 原接口：/api/questionnaire/version/info
export const getLayout = questionnaireVersionId => request('/ctQuestionnaire/api/answer/questionnaire/version/info', {
  params: { questionnaireVersionId },
});

// 保存、提交问卷接口，注意key
export const sendAnswer = (activityId, subjects, questionnaireId, respondentTypeCode) => request('/ctAnswer/api/answer/save', {
  method: 'POST',
  data: {
    activityId,
    questionnaireId,
    subjects,
    respondentTypeCode
  },
});

// 获取侧边栏问卷名称
// 原接口：/api/activity/respondent/groupRespondentAndContentId
export const getActivityDetail = activityId => {
  const ctLang = getCookie('ct-lang') || getLocale('lang');
  return request('/ctActivity/api/answer/activity/respondent/groupRespondentAndContentId', {
    method: 'GET',
    params: {
      activityId,
      languageType: getLangCode(ctLang)
    }
  });
};

// 获取活动调研方式，这个接口要第一个调
export const getSurveyInfo = (activityId) => request('/ctActivity/api/answer/activity/surveyInfo', {
  method: 'GET',
  params: { activityId },
});

// 问卷库预览获取问卷的调研方式，这个接口要第一个调
export const getQuestionSurveyInfo = (questionnaireId) => request('/ctQuestionnaire/api/questionnaire/surveyInfo', {
  method: 'GET',
  role: getCookie('ct_role'),
  params: {
    questionnaireId
  },
});

// 获取匿名key,提交问卷时，需要传入busType=1获取key,获取进度时，传入2
// 原接口：/api/anymous/encode
export const getKey = (activityId, busType, respondentCodeStr, respondentTypeCode) => request('/ctAnonymity/api/answer/anymous/encode', {
  method: 'POST',
  data: { encodeText: activityId, busType, respondentCodeStr, respondentTypeCode },
});

// 通过问卷信息获取问卷最新时间戳，用来终止其他页面
export const getQuesDateFn = (activityId, questionnaireId, respondentCode, versionNumber, respondentTypeCode) => request('/ctAnswer/api/answer/getVersionNumber', {
  method: 'POST',
  data: {
    activityId,
    questionnaireId,
    respondentCode,
    versionNumber,
    respondentTypeCode
  },
});

// 2.0获取问卷题目信息
// 原接口：/api/questionnaireSubject/getAnswerQuestionnaireSubject

export const getSubject = (language, questionnaireSubjectId, questionnaireVersionId, respondentTypeCode, activityId, respondentCodeStr, blockCount) =>
  request('/ctQuestionnaire/api/answer/questionnaireSubject/getAnswerQuestionnaireSubject', {
    params: {
      language,
      questionnaireSubjectId,
      questionnaireVersionId,
      respondentTypeCode,
      activityId,
      respondentCodeStr,
      blockCount
    }
  });

export const networkOutage = (val = {}) => {
  // 服务端异常或者浏览器拦截
  // if (JSON.stringify(val) === '{}' || val?.code !== '100200') {
  if (val?.code !== '100200' && JSON.stringify(val) !== '{}') {
    return false;
    // eslint-disable-next-line no-else-return
  } else {
    return true;
  }
};

// 获取所有问卷题目
export const getSubjectAll = (language, questionnaireVersionId, respondentTypeCode, activityId) =>
  request('/ctQuestionnaire/api/answer/questionnaireSubject/getAllAnswerQuestionnaireSubject', {
    params: { language, questionnaireVersionId, respondentTypeCode, activityId }
  });

// 问卷库预览，按块获取题目信息
export const getSubjectPreview = (language, questionnaireSubjectId, questionnaireId, versionCode, blockCount) =>
  request('/ctQuestionnaire/api/questionnaireSubject/listBlockQuestionnaireSubject', {
    role: getCookie('ct_role'),
    params: { language, questionnaireSubjectId, questionnaireId, versionCode, blockCount }
  })


// 获取问卷库预览问卷包含的语言
export const getQuestionnaireLanguage = questionnaireId => request('/ctQuestionnaire/api/questionnaire/getQuestionnaireLanguage', {
  role: getCookie('ct_role'),
  params: { questionnaireId }
});


// 获取问卷库预览问卷的问卷信息，包括显示题的题目list
export const getQuestionnaireInfo = (language, questionnaireId) => request('/ctQuestionnaire/api/questionnaireSubject/questionnaireBlockInfo', {
  role: getCookie('ct_role'),
  params: { language, questionnaireId }
});

// 获取活动配置问卷预览信息
export const getQuestionnaireGeneralFromSetting = (activityId, participantWorkNo) => {
  const ctLang = getCookie('ct-lang') || getLocale('lang');
  return (
    request('/ctActivity/api/activity/participant/getRespondentGroupInfo', {
      params: { activityId, participantWorkNo, languageType: getLangCode(ctLang) }
    })
  );
};

// 获取活动配置预览问卷的问卷信息，包括显示题的题目list
export const getQuestionnaireInfoFromSetting = (activityId, participantWorkNo, languageType, respondentTypeCode) => request('/ctActivity/api/activity/participant/questionnaireVersionBlockInfo', {
  params: { activityId, participantWorkNo, languageType, respondentTypeCode }
});

// 活动配置预览，按块获取题目信息
export const getSubjectPreviewFromSetting = (activityId, participantWorkNo, languageType, questionnaireVersionId, questionnaireSubjectVersionId, respondentTypeCode, blockCount, versionCode) =>
  request('/ctActivity/api/activity/participant/listBlockQuestionnaireSubjectVersion', {
    params: { activityId, participantWorkNo, languageType, questionnaireVersionId, questionnaireSubjectVersionId, respondentTypeCode, blockCount, versionCode }
  })

// 原接口：/api/activity/respondent/groupRespondentAndContentId
export const groupRespondentAndContentId = (activityId, participantCode) => {
  const ctLang = getCookie('ct-lang') || getLocale('lang');
  return request('/ctActivity/api/answer/activity/respondent/groupRespondentAndContentId', {
    method: 'GET',
    params: {
      activityId,
      participantCode,
      all: true,
      languageType: getLangCode(ctLang)
    },
  });
};

// 漏答题： 获取口答题顺序接口
export const getErrorLocationSort = (questionnaireVersionId, respondentTypeCode, questionnaireSubjectVersionIds, timestamp) => request('/ctQuestionnaire/api/answer/questionnaireSubject/missSubjectOrder', {
  method: 'POST',
  data: {
    questionnaireVersionId,
    respondentTypeCode,
    questionnaireSubjectVersionIds,
    timestamp
  },
});

// 获取答复后的隐藏题目列表
export const getDraftHideSubjects = params => request('/ctActivity/api/activity/participant/getDraftHideSubjects', {
  method: 'GET',
  params,
});

// 获取答复后的隐藏题目列表-问卷库
export const getQuestionnareDraftHideSubjects = params => request('/ctQuestionnaire/api/questionnaireSubject/participant/getDraftHideSubjects', {
  method: 'GET',
  role: getCookie('ct_role'),
  params,
});

// 抽奖登录
export const getDrawLogin = (ldap, activityId) => request('/ctActivity/api/answer/activityRaffle', {
  method: 'GET',
  params: {
    ldap,
    activityId,
  },
});
// 新人链接打开调接口
export const getNewEmployeeLink = (sceneId, researchTag) => request('/ctActivity/api/answer/myCurrentFirstOnActivity', {
  method: 'GET',
  params: {
    sceneId,
    researchTag,
  },
});
