import React, { useState, useEffect, useRef } from 'react';
import { Button, message, Spin } from 'antd';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { getLocale, setLocale } from '@didi/di18n-react';
import classnames from 'classnames';
import { di18n } from '@ultra/common';
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import {
  setCookie,
  getCookie,
  setEncode,
  sendAnswerBySubject,
  getProcessAll,
  getPage,
  getLayout,
  sendAnswer,
  getActivityDetail,
  getKey,
  getSurveyInfo,
  getQuesDateFn,
  getErrorLocationSort,
} from '../../utils/Tool';
import { getHideSubjectIds } from '../../utils/questionnaire';
import soSmallheart from "../../images/footremarkheart.svg";
import QuestionnaireProcess from "../../components/QuestionnaireProcess";
import QuestionnaireProcessPc from "../../components/questionnaireProcessPc";
import QuestionnaireProcessPcBlue from '../../components/QuestionnaireProcessPcBlue';
import QuestionnaireBanner from "../../components/questionnaireBanner";
import QuestionnaireErrorLocation from "../../components/questionnaireErrorLocation";
import QuestionnaireErrorLocationBlue from '../../components/questionnaireErrorLocationBlue';
import QuestionnaireOfSingle from "../../components/questionnaireOfSingle";
import QuestionnaireOfMulti from "../../components/questionnaireOfMulti";
import QuestionnaireOfEnps from "../../components/questionnaireOfEnps";
import QuestionnaireOfSingleSubmited from "../../components/questionnaireOfSingleSubmited";
import QuestionnaireOfPre from "../../components/questionnaireOfPre";
import CTModalConfirm from "../../components/CTModalConfirm";
import "./index.scss";
import {
  getRespondentCode,
  setRespondentTypeCode,
  getProcessRate,
  updateValidateFlag,
  updateLoadStatus,
  updateCurrentRespondentCode,
  setCurrentLang,
  setActivityId,
  updateCurrentSubject,
  hiddenAllAttributeData,
  setSurveyBusType,
  updateFetchMore
} from '../../store/questionnaireNew/questionContentSlice'; // 引入actions
// 为了保证返回顺序值准确性，所以用时间戳来确定返回值准确性
let timestamp;
const { intl } = di18n;
const saveTimer = null;
let keyTimer = null;
const endPage = `${location.origin}/questionnaire/activity/not/started`;
const succeed = `${location.origin}/questionnaire/success`;
let changeSaveTimeStamp;
export default props => {
  const dispatch = useDispatch();
  const [key, setKey] = useState('');
  // 因监听key会导致轮巡接口死循环，设置轮巡专用key
  const currentKey = useRef('');
  const [activityStatus, setActivityStatus] = useState();
  const [layoutData, setLayoutData] = useState();
  const loadTimer = useRef(false);
  const [submitBtnStatus, setSubmitBtnStatus] = useState(false);
  const [currentLangDes, setCurrentLangDes] = useState('');
  const [langKeyList, setLangKeyList] = useState([]);
  // 维护一个以语言为Key的list
  const [lanQue, setlanQue] = useState([]);
  // 判断当前问卷是否已经提交，0：初始状态 1：未提交 2：已提交
  const [pageSubmitStatus, setPageSubmitStatus] = useState(0);
  const [dataStatus, setDataStatus] = useState(true);
  // 单人问卷导航栏
  const [singleQuestionaireList, setSingleQuestionaireList] = useState([]);
  // 配置类型、问卷总是、已答问卷数
  const [activityType, setActivityType] = useState();
  // 当前问卷只能有唯一的key
  const currentPageKey = useRef('');
  // 校验未通过提示框
  const [saveModalConfirm, setSaveModalConfirm] = useState({
    visible: false,
    content: null,
    // onOk: () => { }
  });
  // 2.0新增
  const [pageDataAll, setPageDataAll] = useState();
  // 区别单人问卷和多人问卷, 1:单人 2:合并 3:调研问卷
  const [questionaireType, setQuestionaireType] = useState();
  // 区别单人问卷和多人问卷的展示, 1:单人 2:合并 3:调研问卷
  const [questionaireTypeShow, setQuestionaireTypeShow] = useState();
  const [encodeFlag, setEncodeFLag] = useState();
  const { activityId, questionnaireId, respondentCode, index, env }
    = props.match.params;

  // 提交问卷加loading
  const [spinning, setSpinning] = useState(false);
  // 存在其他选项题型时未失焦点提交，有200ms的延时，导致数据不准确 提交时等数据更新再触发提交
  const errors = useRef({
    errorList: {},
    errorListMap: [],
    generalMultiData: null,
  });
  const {
    processRate,
    generalMultiData,
    respondentsGroup,
    respondentTypeCode,
    errorList,
    errorListMap,
    initRequired,
    loading,
    currentLang,
    lanMap,
    lanDes,
    currentSubject,
    currentRespondentCode,
    loadAll,
    respondentCodeArr,
    // 修改的题目Id
    cancelTokenQesId,
    attributeData,
    surveyBusType,
    prevOptionIds,
    fetchMore,
  } = useSelector((state) => state.questionContent);
  // 根据不同的主题给类名
  const getClassNames = () => {
    let prefixCls = '';
    let mainPrefixCls = '';
    switch (surveyBusType) {
      case 'PROFESSIONAL_SERVICES':
        prefixCls = 'question-main-banner-pc';
        mainPrefixCls = 'flex questionnaire-main';
        break;
      case 'NON_PROFESSIONAL_SERVICES':
        prefixCls = 'question-main-banner-pc question-main-banner-pc-blue';
        mainPrefixCls = 'flex questionnaire-main questionnaire-main-blue';
        break;
      default:
        prefixCls = 'question-main-banner-pc';
        mainPrefixCls = 'flex questionnaire-main';
        break;
    }

    return {
      prefixCls,
      mainPrefixCls,
    };
  };

  useEffect(() => {
    errors.current = {
      ...errors.current,
      errorList,
      errorListMap,
      generalMultiData,
    };
  }, [errorList, errorListMap, generalMultiData]);

  useEffect(() => {
    // console.log('==========getModelData', initRequired, currentSubject)
    if (env !== 'pre' && initRequired === -1 && currentSubject) {
      getModelData(1);
    }

  }, [generalMultiData]);

  const changeSaveModalConfirm = () => {
    setSaveModalConfirm({
      ...saveModalConfirm,
      visible: false,
    });
  };

  const encodeFilter = data => {
    if (data === '699999') {
      setSaveModalConfirm({
        visible: true,
        type: 'refresh',
        content: {
          title: intl.t('提示'),
          okText: intl.t('刷新页面'),
          isHaveCelBtn: false,
          content: intl.t(
            '检测到您长时间未填写问卷，已与服务器断开，请刷新后再填问卷！'
          ),
          onOk: () => {
            window.location.reload();
          },
        },
      });
    }
  };

  // 保存草稿，单题提交
  const setDraft = async () => {
    const subjectDraft = {};
    Object.keys(generalMultiData).forEach(i => {
      subjectDraft[i] = {};
      subjectDraft[i][currentSubject] = generalMultiData[i][currentSubject];
    });

    const draftData = {
      activityId,
      questionnaireId,
      respondentTypeCode,
      subjects: subjectDraft,
    };
    // 弱网时 通过cancelTokenQesId  拼接save队列名称
    if (cancelTokenQesId) {
      draftData.cancelTokenQesId = cancelTokenQesId;
    }
    // 不是合并问卷
    if (questionaireTypeShow !== 2) {
      // 找到需要联动隐藏的id
      const { hideSubjectIds = [], cloneData } = getHideSubjectIds({
        generalMultiData, attributeData, currentSubject, prevOptionIds, currentRespondentCode
      });
      hideSubjectIds.forEach(item => {
        cloneData[item].hidden = true;
      });
      // console.log('========setDraft', fetchMore);
      dispatch(updateFetchMore({ data: false }));
      if (!fetchMore) {
        dispatch(hiddenAllAttributeData({ list: hideSubjectIds, respondentTypeCode, cloneData }));
      }
    }

    const { data, code } = await sendAnswerBySubject(draftData);
    encodeFilter(code);
    dispatch(getProcessRate({ data }));
    dispatch(updateCurrentSubject({ data: '' }));
  };

  const getErrorListSort = async () => {
    const { errorListMap } = errors.current;
    timestamp = Date.now();
    const { data } = await getErrorLocationSort(
      questionnaireId,
      respondentTypeCode,
      errorListMap,
      timestamp
    );
    if (timestamp == data.timestamp) {
      document.querySelector(`#id_${data.orderSubjects?.[0]}`).scrollIntoView({
        block: 'center',
        smooth: 'smooth',
      });
    }
  };

  // 提交问卷，提交所有题目数据
  const setAnswer = async () => {
    dispatch(updateValidateFlag({ data: true }));
    setSpinning(true);
    const { errorList, generalMultiData } = errors.current;
    if (errorList.widget === 0 && errorList.other === 0) {
      if (initRequired === -1 || processRate?.progressRate !== 0) {
        // 重新获取匿名key并提交答案
        const keyData = await getKey(
          activityId,
          1,
          respondentCodeArr.toString(),
          respondentTypeCode
        );
        // 匿名接口返回正常才提交问卷
        if (keyData.code === '100200') {
          try {
            const { data, code } = await sendAnswer(
              activityId,
              generalMultiData,
              questionnaireId,
              respondentTypeCode
            );
            encodeFilter(code);
            if (data === 'success') {
              clearInterval(keyTimer);
              const activity = await getActivityDetail(activityId);
              encodeFilter(activity?.code);
              setActivityStatus(activity?.data?.activityStatus);
              setSingleQuestionaireList(activity?.data?.respondentGroup);
              setActivityType(activity?.data?.activityType);
              getProcess(activityId, respondentTypeCode, questionaireType);
              message.success(intl.t('问卷已提交！'));
              setTimeout(() => {
                setSpinning(false);
              }, 2000);
            }
            changeSaveModalConfirm();
          } catch (error) {
            setSpinning(false);
            changeSaveModalConfirm();
            console.log('sendAnswer', error);
          }
        }
      } else {
        let showNum;
        if (processRate?.progressRate === 0 && initRequired !== -1) {
          // 进度为0，说明未填写，则取初始化的必填数量
          showNum = initRequired;
        } else {
          showNum = errorList.widget + errorList.other;
        }
        setSaveModalConfirm({
          visible: true,
          content: {
            title: intl.t('提示'),
            content: intl.t('还有{num}个题目未填写', {
              num: showNum,
            }),
            onOk: () => {
              setSpinning(false);
              changeSaveModalConfirm();
              getErrorListSort();
            },
          },
        });
      }
    } else {
      let showNum;
      if (processRate?.progressRate === 0 && initRequired !== -1) {
        // 进度为0，说明未填写，则取初始化的必填数量
        showNum = initRequired;
      } else {
        // showNum = errorList.widget === 0 ? errorList.other : errorList.widget;
        showNum = errorList.widget + errorList.other;
      }
      setSaveModalConfirm({
        visible: true,
        content: {
          title: intl.t('提示'),
          content: intl.t('还有{num}个题目未填写', {
            num: showNum,
          }),
          onOk: () => {
            setSpinning(false);
            changeSaveModalConfirm();
            getErrorListSort();
          },
        },
      });
    }
  };

  // 点击提交、保存时做的数据校验和数据提交 1：保存 2：提交
  const getModelData = async submitType => {
    if (submitType === 1) {
      setDraft();
    }
    if (submitType === 2) {
      setAnswer();
    }
  };

  // 提交问卷
  const handleSubmit = type => {
    setSaveModalConfirm({
      visible: true,
      type: 'submit',
      content: {
        title: intl.t('提示'),
        content: intl.t('是否确认提交?'),
        onOk: () => {
          if (loadTimer.current === false) {
            loadTimer.current = true;
            getModelData(type);
            setTimeout(() => {
              loadTimer.current = false;
            }, 3000);
          }
        },
      },
    });
  };

  // 接口异常处理
  const networkOutage = (val = {}) => {
    // 服务端异常或者浏览器拦截
    if (val?.code !== '100200' && JSON.stringify(val) !== '{}') {
      clearInterval(keyTimer);
      return false;
      // eslint-disable-next-line no-else-return
    } else {
      return true;
    }
  };

  const getProcess = async (
    id,
    respondentTypeCodeParam,
    questionaireTypeParam
  ) => {
    const process = await getProcessAll(id, respondentTypeCodeParam);

    const processData = process.data;
    if (questionaireTypeParam === 1) {
      for (const item in processData) {
        if (
          processData[item].questionnaireId === questionnaireId
          && processData[item].respondentCode === respondentCode
        ) {
          if (processData[item].recycleStatus === 2) {
            setSubmitBtnStatus(true);
            setPageSubmitStatus(2);
          } else {
            setPageSubmitStatus(1);
          }
        }
      }
    } else if (processData[0].groupRecycleStatus === 2) {
      setSubmitBtnStatus(true);
      setPageSubmitStatus(2);
    } else {
      setPageSubmitStatus(1);
    }
  };

  useEffect(async () => {
    let ctLang = getCookie('ct-lang');

    if (env === 'pre') {
      ctLang = 'zh-CN';
    }
    const lang = getLocale('lang');

    if (ctLang && ctLang !== 'undefined') {
      if (lang !== ctLang) {
        setLocale(ctLang, {
          cookieLocaleKey: 'lang',
        });
      }
    }
    const current = ctLang !== 'undefined' ? ctLang : lang;

    let currentMap = 0;
    lanMap.map((item, i) => {
      if (item === current) {
        currentMap = i;
      }
    });
    dispatch(setCurrentLang({ data: currentMap }));
    setCurrentLangDes(lanDes[currentMap]);

    // 进入页面先获取当前问卷的主题：PROFESSIONAL_SERVICES-暖橙色；NON_PROFESSIONAL_SERVICES-科技蓝
    const {data: {surveyBusType}} = await getSurveyInfo(activityId);
    dispatch(setSurveyBusType({ data: surveyBusType }));

    const { data } = await setEncode({ encodeText: activityId, busType: 2 });
    if (data) {
      setEncodeFLag(true);
    }
  }, []);

  useEffect(() => {
    if (
      activityId
      && questionnaireId
      && respondentCode
      && pageSubmitStatus
      && respondentTypeCode
    ) {
      keyTimer = setInterval(async () => {
        // 状态为已提交清定时器
        if (pageSubmitStatus === 2) {
          clearInterval(keyTimer);
          clearInterval(saveTimer);
          return;
        }
        const getQuesDate = await getQuesDateFn(
          activityId,
          questionnaireId,
          respondentCode,
          currentPageKey.current,
          respondentTypeCode
        );
        encodeFilter(getQuesDate.code);
        if (getQuesDate.data) {
          if (currentPageKey.current) {
            if (getQuesDate.data !== String(currentPageKey.current)) {
              clearInterval(keyTimer);
              clearInterval(saveTimer);
              setSaveModalConfirm({
                visible: true,
                content: {
                  title: intl.t('提示'),
                  okText: null,
                  isHaveCelBtn: false,
                  content: intl.t(
                    '您有新打开的问卷页面，当前页面已失效，请关闭后至新页面进行填写'
                  ),
                },
              });
              return;
            }
            if (pageSubmitStatus === 2) {
              clearInterval(keyTimer);
            }
          } else {
            currentPageKey.current = getQuesDate.data;
          }
        }
      }, 3000);
    }
  }, [
    activityId,
    questionnaireId,
    respondentCode,
    pageSubmitStatus,
    respondentTypeCode,
  ]);

  useEffect(() => {
    (async () => {
      dispatch(setActivityId({ data: activityId }));
      if (questionnaireId && activityId && currentLang > -1 && encodeFlag) {
        let avaliableLan = currentLang;
        // 获取题目数据
        const layout = await getLayout(questionnaireId);
        const layoutDataAll = layout.data;
        let showLang = avaliableLan;
        for (const item in layoutDataAll) {
          if (env && env === 'pre') {
            showLang = 0;
          }
          if (layoutDataAll[item].languageType === showLang) {
            const layoutDataTmp = layoutDataAll[item];
            setLayoutData(layoutDataTmp);
            setDataStatus(false);
          }
        }

        if (env !== 'pre') {
          // 获取侧边问卷名称及状态，传给process组件渲染侧边栏问卷名称
          const activity = await getActivityDetail(activityId);
          encodeFilter(activity?.code);
          setActivityStatus(activity?.data?.activityStatus);
          setSingleQuestionaireList(activity?.data?.respondentGroup);
          setActivityType(activity?.data?.activityType);
          let questionaireFlag;
          // 判断问卷类型
          if (activity.data) {
            switch (activity.data.questionnaireLayoutType) {
              case 0: // 单人问卷
                setQuestionaireType(1);
                questionaireFlag = 1;
                break;
              case 1: // 合并问卷
                setQuestionaireType(2);
                questionaireFlag = 2;
                break;
              case 2: // 调研问卷
                setQuestionaireType(3);
                setQuestionaireTypeShow(3);
                dispatch(updateCurrentRespondentCode({ data: respondentCode }));
                questionaireFlag = 3;
                break;
              default:
                console.log('undefined questionnaireLayoutType');
            }
          }

          // ！！！如果为合并问卷，则需要判断respondentGroup的索引值
          // 修改索引
          const activityData = activity.data.respondentGroup[index];
          dispatch(
            setRespondentTypeCode({ data: activityData?.respondentTypeCode })
          );

          let respondentParam;
          let lanType = [];

          if (questionaireFlag === 1) {
            if (activityData.contentInfos.length === 0) {
              props.history.replace(
                `/pc/${activityId}/${questionnaireId}/${respondentCode}/submit/0`
              );
              return;
            }
            activityData?.contentInfos?.map((item, i) => {
              const itemRespondentCode
                = item.activityRespondents[0].respondentCode;
              if (itemRespondentCode === respondentCode) {
                respondentParam
                  = activityData.contentInfos[i].activityRespondents;
                lanType = activityData.contentInfos[i].languageTypes;
              }
            });
          }
          if (questionaireFlag === 2) {
            if (activityData.contentInfos.length === 0) {
              props.history.replace(`/navigation/pc/${activityId}`);
              return;
            }
            respondentParam = activityData.contentInfos[0]?.activityRespondents;
            lanType = activityData.contentInfos[0]?.languageTypes;
          }
          if (questionaireFlag === 3) {
            respondentParam = activityData.contentInfos[0]?.activityRespondents;
            lanType = activityData.contentInfos[0]?.languageTypes;
          }
          dispatch(getRespondentCode({ data: respondentParam }));
          if (questionaireFlag !== 3) {
            if (respondentParam && respondentParam.length > 1) {
              setQuestionaireTypeShow(2);
            } else {
              if (
                questionaireFlag === 2
                && respondentParam?.length
                && respondentParam[0].respondentCode !== respondentCode
              ) {
                // console.log('here')
                props.history.replace(`/navigation/pc/${activityId}`);
              }
              setQuestionaireTypeShow(1);
              dispatch(updateCurrentRespondentCode({ data: respondentCode }));
            }
          }
          // 判断当前问卷支持的语言与cookie里存的是否匹配，如果不匹配就把cookie切成问卷返回的第一个语言
          if (!lanType.includes(currentLang)) {
            if (lanType.includes(1)) {
              // 若问卷包含英文则请求英文问卷
              avaliableLan = 1;
            } else {
              // 若无英文，则请求问卷的第一个语言
              avaliableLan = lanType[0];
            }

            setLocale(lanMap[avaliableLan], {
              cookieLocaleKey: 'lang',
            });
            setCookie('ct-lang', lanMap[avaliableLan]);
            dispatch(setCurrentLang({ data: avaliableLan }));
            setCurrentLangDes(lanDes[avaliableLan]);

            // 如果本地语言不在当前问卷语言列表中，则刷新页面
            window.location.reload();
            return;
          }

          const { data } = await getPage(
            avaliableLan,
            questionnaireId,
            activityId,
            activityData?.respondentTypeCode
          );
          setPageDataAll(data);
          // 处理respondentCode

          await getProcess(
            activityId,
            activityData?.respondentTypeCode,
            questionaireFlag
          );

          // 获取问卷进度

          // 判断需要填写的问卷都包含哪些语言
          let langAll = [];
          const langQuestionnaireArr = new Array();
          for (const item in activityData?.contentInfos) {
            const languageType = activityData.contentInfos[item].languageTypes;
            langAll = langAll.concat(languageType);
            // 维护一个以语言为Key的问卷List，目的是为了根据当前语言判断包含哪些问卷
            languageType.map(lan => {
              if (!langQuestionnaireArr[lan]) {
                langQuestionnaireArr[lan] = [];
              }
              langQuestionnaireArr[lan].push(
                `${activityData.contentInfos[item].contentId}/${activityData.contentInfos[item].activityRespondents[0].respondentCode}`
              );
            });
          }
          setlanQue(langQuestionnaireArr);
          setLangKeyList(Array.from(new Set(langAll)));

          if (activity.data?.activityStatus !== 1) {
            // 如果活动状态不为1，则弹出提示活动未开始或已经结束
            location.replace(endPage);
          }
        } else {
          // 如果为预览模式，就按单人问卷形式展示
          setQuestionaireType(1);
          const { data } = await getPage(
            avaliableLan,
            questionnaireId,
            activityId
          );
          setPageDataAll(data);
          dispatch(updateCurrentRespondentCode({ data: respondentCode }));
        }
      }
    })();
  }, [questionnaireId, activityId, currentLang, encodeFlag]);

  const handleReturnBtn = () => {
    location.href = `${location.origin}/questionnaire/navigation/pc/${activityId}`;
  };

  const toNavigations = () => {
    if (questionaireType === 2) {
      handleReturnBtn();
    }
    if (questionaireType === 3) {
      location.replace(`${succeed}?id=${activityId}`);
    }
  };

  const navigate = () => (
    <>
      <QuestionnaireProcess
        activityId={activityId}
        respondentCode={respondentCode}
        singleQuestionaireList={singleQuestionaireList}
        index={index}
        questionaireType={questionaireType}
        activityType={activityType}
      />
    </>
  );

  const renderBanner = (lanSelect = true) => (
    <>
      <QuestionnaireBanner
        activityId={activityId}
        layoutData={layoutData}
        currentLangDes={currentLangDes}
        questionnaireId={questionnaireId}
        respondentCode={respondentCode}
        langKeyList={langKeyList}
        lanSelect={lanSelect}
        questionaireType={questionaireType}
        lanQue={lanQue}
        index={index}
      />
    </>
  );
  const renderProcess = () => (
    <>
      {
        surveyBusType === 'PROFESSIONAL_SERVICES' ? (
          <QuestionnaireProcessPc processRate={processRate} />
        ) : (
          <QuestionnaireProcessPcBlue processRate={processRate} />
        )
      }
    </>
  );
  const renderErrorLocation = () => (
    <>
      {
        surveyBusType === 'PROFESSIONAL_SERVICES' ? (
          <QuestionnaireErrorLocation
            saveModalConfirm={saveModalConfirm}
            questionnaireId={questionnaireId}
            respondentTypeCode={respondentTypeCode}
          />
        ) : (
          <QuestionnaireErrorLocationBlue
            saveModalConfirm={saveModalConfirm}
            questionnaireId={questionnaireId}
            respondentTypeCode={respondentTypeCode}
          />
        )
      }
    </>
  );

  const renderSubmitBtn = () => {
    const prefixCls = classnames('question-subumit-pc', {
      'question-subumit-pc-blue': surveyBusType === 'NON_PROFESSIONAL_SERVICES'
    });

    return (
      <div className={prefixCls}>
        {loadAll ? (
          <Button
            className="question-submit-pc-submit"
            disabled={submitBtnStatus}
            onClick={() => handleSubmit(2)}
          >
            {intl.t("提交")}
          </Button>
        ) : (
          ""
        )}
      </div>
    );
  };

  const renderFootRemark = () => (
    <div className="question-footer-concluding-remarks">
      <span>{intl.t('感谢您的帮助与支持')}</span>
      <img style={{ padding: '0 0 4px 6px' }} src={soSmallheart} />
    </div>
  );

  const renderConfirm = () => (
    <>
      {saveModalConfirm.visible && (
        <CTModalConfirm
          modalContent={saveModalConfirm.content}
          changeSaveModalConfirm={changeSaveModalConfirm}
          type={saveModalConfirm.type ? saveModalConfirm.type : ''}
          disabled={spinning}
          setSpinning={setSpinning}
          surveyBusType={surveyBusType}
        />
      )}
    </>
  );

  const renderFoot = () => (
    <>
      {!!layoutData.pageFooting && (
        <div className="question-main-des-pc">
          <div>{layoutData.pageFooting}</div>
        </div>
      )}
    </>
  );

  const renderQuestionaire = () => (
    <>
      <QuestionnaireOfSingle
        renderBanner={renderBanner()}
        navigate={navigate()}
        renderSubmitBtn={renderSubmitBtn()}
        // renderFootRemark={renderFootRemark()}
        renderProcess={renderProcess()}
        renderErrorLocation={renderErrorLocation()}
        renderFoot={renderFoot()}
        pageDataAll={pageDataAll}
        reviewType={2}
        renderType={1}
      />
    </>
  );

  const renderMultiQuestionaire = () => (
    <>
      <QuestionnaireOfMulti
        getClassNames={getClassNames()}
        renderBanner={renderBanner()}
        renderSubmitBtn={renderSubmitBtn()}
        // renderFootRemark={renderFootRemark()}
        renderProcess={renderProcess()}
        renderErrorLocation={renderErrorLocation()}
        renderFoot={renderFoot()}
        pageDataAll={pageDataAll}
        reviewType={2}
        renderType={1}
      />
    </>
  );

  const renderEnpsQuestionaire = () => (
    <>
      <QuestionnaireOfEnps
        getClassNames={getClassNames()}
        renderBanner={renderBanner()}
        renderSubmitBtn={renderSubmitBtn()}
        // renderFootRemark={renderFootRemark()}
        renderProcess={renderProcess()}
        renderErrorLocation={renderErrorLocation()}
        renderFoot={renderFoot()}
        pageDataAll={pageDataAll}
        reviewType={2}
        renderType={1}
      />
    </>
  );
  const renderPre = () => (
    <>
      <QuestionnaireOfPre
        renderBanner={renderBanner(false)}
        renderFoot={renderFoot()}
        // renderFootRemark={renderFootRemark()}
        pageDataAll={pageDataAll}
        questionaireType={questionaireType}
      />
    </>
  );

  const renderSubmited = () => (
    <>
      <QuestionnaireOfSingleSubmited
        renderBanner={renderBanner()}
        navigate={navigate()}
        questionaireType={questionaireType}
      />
    </>
  );

  const renderResult = () => {
    if (env === 'pre') {
      return renderPre();
    }
    if (pageSubmitStatus === 2) {
      if (questionaireType === 1) {
        return renderSubmited();
      }
      dispatch(updateLoadStatus({ data: false }));
      toNavigations();
    }

    if (questionaireTypeShow === 1) {
      return renderQuestionaire();
    }

    if (questionaireTypeShow === 2) {
      return renderMultiQuestionaire();
    }

    if (questionaireTypeShow === 3) {
      return renderEnpsQuestionaire();
    }
  };

  return (
    <>
      {!dataStatus ? (
        <Spin
          spinning={loading}
          tip={intl.t('问卷加载中，请稍后…')}
          wrapperClassName="w-full"
        >
          <div className={classnames('w-full h-full question-homepage', {
            'question-homepage-blue': surveyBusType === 'NON_PROFESSIONAL_SERVICES'
          })}>
            <Spin spinning={spinning}>{renderResult()}</Spin>
          </div>
        </Spin>
      ) : (
        <div style={{ height: '100vh' }} />
      )}
      {renderConfirm()}
    </>
  );
};
